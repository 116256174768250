import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import './index.css';

import rootReducer from './redux/reducers';

const getStoredCartItems = () => {
  const cart = localStorage.getItem('rizzy_cart_items');
  if (!cart) return {};
  try {
    return JSON.parse(cart);
  } catch (e) {
    return {};
  }
}

const initialState = {
  cart: {
    items: getStoredCartItems()
  }
}

console.log(initialState)

const store = createStore(rootReducer, initialState, applyMiddleware(thunk));

store.subscribe(() => {
  console.log('IN SUBSCRIBE....')
  const items = store.getState().cart.items;
  localStorage.setItem('rizzy_cart_items', JSON.stringify(items))
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
