import {
  ADD_TO_CART,
  CLEAR_CART,
  OPEN_CART,
  CLOSE_CART,
  ADD_ONE,
  MINUS_ONE,
} from '../actionTypes';

const initialState = {
  items: {},
  open: false,
};

const cart = (state = initialState, action) => {
  switch(action.type) {
    case ADD_TO_CART: {
      const {
        item: {
          title,
          price,
          stripeId,
          images,
        } = {}
      } = action.payload;
      const {
        [title]: {
          count: currentCount = 0,
          ...rest
        } = {}
      } = state.items;
      return {
        ...state,
        items: {
          ...state.items,
          [title]: {
            ...rest,
            count: currentCount + 1,
            price,
            stripeId: stripeId,
            image: images[0],
            title,
          }
        }
      }
    }
    case CLEAR_CART: {
      return {
        ...state,
        open: false,
        items: {}
      }
    }
    case OPEN_CART: {
      return {
        ...state,
        open: true,
      }
    }
    case CLOSE_CART: {
      return {
        ...state,
        open: false,
      }
    }
    case ADD_ONE: {
      const {
        item: {
          title,
          price,
          stripeId,
          images,
        } = {}
      } = action.payload;
      const {
        [title]: {
          count: currentCount = 0,
          ...rest
        } = {}
      } = state.items;
      return {
        ...state,
        items: {
          ...state.items,
          [title]: {
            ...rest,
            price,
            count: currentCount + 1,
          }
        }
      }
    }
    case MINUS_ONE: {
      const {
        item: {
          title,
          price,
          stripeId,
          images,
        } = {}
      } = action.payload;
      const {
        [title]: {
          count: currentCount = 0,
          ...rest
        } = {},
        ...restOfItems
      } = state.items;
      const itemCount = Object.keys(state.items).reduce((acc, key) => {
        return acc + state.items[key].count;
      }, 0);
      if (currentCount === 1) {
        return {
          ...state,
          open: itemCount === 1 ? false : state.open,
          items: {
            ...restOfItems
          }
        }
      }
      return {
        ...state,
        items: {
          ...state.items,
          [title]: {
            ...rest,
            count: currentCount - 1,
          }
        }
      }
    }
    default: 
      return state;
  }
}

export default cart;
