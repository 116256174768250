import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { clearCart } from './redux/actions';

const CheckoutSuccess = props => {

  useEffect(() => {
    props.clearCart();
  }, []);

  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: '45px'
      }}
    >
      Thanks for your order
    </div>
  );
}

export default connect(null, { clearCart })(CheckoutSuccess);
