import React from 'react';
import { connect } from 'react-redux';

import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

import { openCart } from './redux/actions'

const mapStateToProps = (state, ownProps) => ({
  cartCount: Object.keys(state.cart.items).reduce((acc, key) => {
    return acc + state.cart.items[key].count;
  }, 0)
});

const CartIcon = (props) => {
  return (
    <div
      onClick={props.openCart}
      style={{
        cursor: 'pointer',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'fixed',
        top: 20,
        right: 20
      }}
    >
      <ShoppingCartOutlinedIcon style={{ fontSize: '32px' }}/> ({props.cartCount})
    </div>
  );
}

export default connect(mapStateToProps, { openCart })(CartIcon);
