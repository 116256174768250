import React, { Component } from 'react';
import './App.css';
import request from 'request';
import Lightbox from 'react-images';
import AlbumPlayer from './AlbumPlayer';
import { Helmet } from 'react-helmet';

class Album extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.clickFront = this.clickFront.bind(this);
    this.clickBack = this.clickBack.bind(this);
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }

  componentWillMount() {
    const slug = this.props.match.params.albumSlug;
    const baseUrl = 'https://cdn.contentful.com/spaces/j9eel39hrxng/environments/master/entries';
    const qs = {
      access_token: '06711dad040ed332146d711ed15914aa2e8a70d859975a94a89e05bebb19bd35',
      include: 1,
      content_type: 'album',
      'fields.slug': slug,
    };
    request({
      uri: baseUrl,
      method: 'GET',
      json: true,
      qs,
    }, (err, response, body) => {
      const assetMap = body.includes.Asset.reduce((acc, asset) => {
        return Object.assign(acc, { [asset.sys.id]: asset.fields });
      }, {});
      const album = body.items.slice(0, 1).map((item) => {
        const {
          fields: {
            title,
            slug,
            tracks,
            frontCover: {
              sys: {
                id: frontId,
              } = {},
            } = {},
            backCover: {
              sys: {
                id: backId,
              } = {},
            } = {},
          } = {},
        } = item;
        return {
          title,
          slug,
          front: assetMap[frontId].file.url,
          back: assetMap[backId] && assetMap[backId].file.url,
          tracks: tracks.map((track) => {
            const id = track.sys.id;
            return {
              title: assetMap[id].title,
              url: assetMap[id].file.url,
              length: 5,
            }
          }),
        };
      })[0];
      this.setState({
        album,
        selectedImage: album.front,
      });
    });
  }

  handleClick(e) {
    this.setState({
      bigImg: e.target.src,
    });
  }

  clickFront() {
    this.setState({
      selectedImage: this.state.album.front,
      currentImage: 0,
    });
  }
  clickBack() {
    this.setState({
      selectedImage: this.state.album.back,
      currentImage: 1,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  openLightbox() {
    this.setState({
      lightboxIsOpen: true,
    });
  }

  render() {
    const shouldRender = this.state && this.state.album;

    if (shouldRender) {
      const images = [{
        src: this.state.album.front,
      }];
      if (this.state.album.back) {
        images.push({
          src: this.state.album.back,
        });
      }
      const album = this.state.album;
      return (
        <div id="album">
          <div id="bc-player">
            <AlbumPlayer click={this.props.update} title={this.state.album.title} songs={this.state.album.tracks}/>
          </div>
          <div className="col-md-8 covers">
            <div className="thumbs" onClick={ this.handleClick }>
              <div onClick={ this.clickFront }>
                <img alt="" src={ this.state.album.front }/>
              </div>
              {
                this.state.album.back ? (
                  <div onClick={this.clickBack} >
                    <img alt="" src={ `${this.state.album.back}` }/>
                  </div>
                ) : null
              }
            </div>
            <img className="cover-selected" alt="" width="100px" src={ this.state.selectedImage } onClick={ this.openLightbox }/>
            <Lightbox images={images || []}
              onClose={this.closeLightbox}
              onClickPrev={this.gotoPrevious}
              onClickNext={this.gotoNext}
              currentImage={this.state.currentImage}
              isOpen={this.state.lightboxIsOpen}
              backdropClosesModal={true}
            />
          </div>
          <Helmet>
            <meta property="og:url"                content={ `https://www.rizzybeats.com/music/${ album.slug }` } />
            <meta property="og:title"              content={ `RizzyBeats - ${ album.title }` } />
            <meta property="og:image"              content={ album.front } />
            <meta name="twitter:title"             content={ `RizzyBeats - ${ album.title }` } />
            <meta name="twitter:image"             content={ album.front } />
          </Helmet>
        </div>
      );
    } else {
      return (<div></div>)
    }
  }
}

export default Album;
