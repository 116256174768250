import React, { Component } from 'react';
import Slider from 'rc-slider/lib/Slider';
import './player.css';
import 'rc-slider/assets/index.css';
import Library from './library';
import request from 'request';

class Player extends Component {

  constructor(props) {
    super(props);
    this.rewind = this.rewind.bind(this);
    this.pause = this.pause.bind(this);
    this.forward = this.forward.bind(this);
    this.volume = this.volume.bind(this);
    this.timeUpdate = this.timeUpdate.bind(this);
    this.onSlide = this.onSlide.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.expandLibrary = this.expandLibrary.bind(this);
    this.loaded = this.loaded.bind(this);
    this.update = this.update.bind(this);
    this.state = {
      albums: [],
      libraryExpanded: false,
      playing: props.playing,
      progress: 0,
      track_position: '0:00',
    };
  }

  componentWillMount() {
    const baseUrl = 'https://cdn.contentful.com/spaces/j9eel39hrxng/environments/master/entries';
    const qs = {
      access_token: '06711dad040ed332146d711ed15914aa2e8a70d859975a94a89e05bebb19bd35',
      include: 1,
    };
    request({
      uri: baseUrl,
      method: 'GET',
      json: true,
      qs: {
        ...qs,
        content_type: 'album',
      },
    }, (err, response, body) => {
      const assetMap = body.includes.Asset.reduce((acc, asset) => {
        return Object.assign(acc, { [asset.sys.id]: asset.fields });
      }, {});
      this.setState({
        albums: body.items.map((item) => {
          const {
            fields: {
              title,
              tracks,
              artist,
            } = {},
          } = item;
          return {
            title,
            artist,
            tracks: tracks.map((track) => {
              const id = track.sys.id;
              return {
                title: assetMap[id].title,
                url: assetMap[id].file.url,
                length: 5,
              }
            }),
          };
        }),
      });
    });
  }

  rewind() {
    // var audioElement = this.audioElement;
    // audioElement.currentTime -= 10;
    // var val = audioElement.currentTime;
    // this.setState({
    //   progress: (audioElement.currentTime / this.state.track_length)
    // })

    const { album_idx, track_idx } = this.props.track;

    const album = this.state.albums[album_idx];

    // debugger;

    const track = album.tracks[track_idx - 1];

    const clickPayload = {
      album: album.title,
      title: track.title,
      url: track.url,
      artist: album.artist,
      length: track.length,
      album_idx: album_idx,
      track_idx: track_idx - 1,
    };

    this.props.update(clickPayload);
  }

  pause() {
    if (this.props.playing) {
      this.props.pause()
    } else {
      this.props.play()
    }
    // const a = this.audioElement;
    // a.paused ? a.play() : a.pause();
    // this.setState({
    //   playing: !a.paused,
    // });
  }

  forward() {
    // const { audioElement } = this;
    // audioElement.currentTime += 10;
    // const val = audioElement.currentTime;
    // this.setState({
    //   progress: (audioElement.currentTime / this.state.track_length),
    // });

    const { album_idx, track_idx } = this.props.track;

    const album = this.state.albums[album_idx];

    // debugger;

    const track = album.tracks[track_idx + 1];

    const clickPayload = {
      album: album.title,
      title: track.title,
      url: track.url,
      artist: album.artist,
      length: track.length,
      album_idx: album_idx,
      track_idx: track_idx + 1,
    };

    this.props.update(clickPayload);
  }

  volume() {

  }

  timeUpdate(e) {
    const { audioElement } = this;
    this.setState({
      track_position: audioElement.currentTime,
      // progress: audioElement.currentTime,
      progress: (audioElement.currentTime / this.state.track_length)
    });
  }

  onSlide(val) {
    const { audioElement } = this;
    audioElement.currentTime = val;
    this.setState({
      progress: (audioElement.currentTime / this.state.track_length),
    });
  }

  updateProgress(e) {
    console.log('in updateProgress', this.state.progress);
    const newProgress = this.state.progress + 1;
    if(!this.state.dragging) {
      this.setState({
        progress: newProgress,
      });
    }
  }

  expandLibrary() {
    this.setState({
      libraryExpanded: !this.state.libraryExpanded,
    });
  }

  formatTime(s) {
    var minutes = Math.floor(s / 60);
    var seconds = s - minutes * 60;
    seconds = Math.round(seconds);
    if (seconds < 10) {
      seconds = "0"+seconds;
    }
    if (Number.isNaN(minutes)) return '0:00';
    return minutes+":"+seconds;
  }

  loaded() {
    const { audioElement } = this;
    this.setState({
      track_length: audioElement.duration,
    });
  }

  componentDidMount() {
    var audioElement = this.audioElement;
    // audioElement.addEventListener('progress', this.updateProgress);
    audioElement.addEventListener('timeupdate', this.timeUpdate);
    audioElement.addEventListener('ended', this.handleMediaEnd);
    audioElement.addEventListener('canplay', this.loaded);
    this.setState({
      progress: 0
    })
  }

  componentWillReceiveProps(nextProps) {
    console.log('got new props?', nextProps, this.props);
    if (this.props.track !== nextProps.track) {
      this.audioElement.load();
      
      this.audioElement.play();

      this.setState({ playing: true });
    }

    if (this.props.playing !== nextProps.playing) {
      if (!nextProps.playing) {
        this.audioElement.pause();
      } else {
        this.audioElement.play();
      }
      this.setState({ playing: nextProps.playing });
    }
  }

  update(clickPayload) {
    this.setState({ libraryExpanded: false });
    this.props.update(clickPayload);
  }

  render() {
    return (
      <div id="player">
        <div id="controls">
          <span id="left" onClick={ this.rewind } className="control glyphicon glyphicon-fast-backward" aria-hidden="true"></span>
          <span id="pause" onClick={ this.pause } className={ this.state.playing ? "control glyphicon glyphicon-pause" : "control glyphicon glyphicon-play"} aria-hidden="true"></span>
          <span id="right" onClick={ this.forward } className="control glyphicon glyphicon-fast-forward" aria-hidden="true"></span>
        </div>
        <div className="ib hide-small" id="track">
          <span id="current_time">{ this.formatTime(this.state.track_position) }</span>
          <div id="progress">
            <Slider
              value={ this.state.progress ? this.state.progress * 100 : 0 }
              onBeforeChange={ ()=>{this.setState({dragging:true})} }
              onAfterChange={ ()=>{this.setState({dragging:false})} }
              onChange={ this.onSlide }
            />
          </div>
          <span>{ this.formatTime(this.state.track_length) }</span>
        </div>
        <span id="info" className="bonnie">
          { this.props.track.title }<span className="hide-small"> - { this.props.track.artist } - { this.props.track.album }</span>
          </span>
        <div id="library" className="ib" onClick={ this.expandLibrary }>
          <span id="librffary" className="bonnie">Library</span>
          <span className="glyphicon glyphicon-chevron-up" aria-hidden="true"></span>
        </div>
        <Library shown={ this.state.libraryExpanded } albums={ this.state.albums } update={ this.update } />
        <audio autoPlay={ this.props.track.url } loop ref={audio => { this.audioElement = audio; }}>
          <source src={ this.props.track.url } type="audio/mpeg"/>
        </audio>
      </div>
    );
  }
}
//autoplay
export default Player;
