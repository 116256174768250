import React, { useState, useEffect } from 'react';
import request from 'request';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.css';

import { addToCart, clearCart } from './redux/actions'

const mapStateToProps = (state, ownProps) => {
  return { cartItems: state.cart.items, cartCount: Object.keys(state.cart.items).length };
}

const countDownDate = new Date("Nov 18, 2022 12:00:00").getTime();
const secondsToGo = ((countDownDate / 1000 - (new Date().getTime() / 1000)))
const disableShop = secondsToGo > 0;

const Timer = ({ seconds }) => {
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(seconds * 1000);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1000);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const s = Math.floor((timeLeft % (1000 * 60)) / 1000);

  // Display the result in the element with id="demo"
  const result = days + "d " + hours + "h "
  + minutes + "m " + s + "s ";

  return (
    <div
      style={{
        textAlign: 'center',
      }}
      className="bonnie shop-banner">
      Shop coming in: {result}
    </div>
  );
};

function Shop(props) {
  const [merch, setMerch] = useState([]);

  const params = new URLSearchParams(props.location.search);
  const foo = params.get('enabled');

  useEffect(() => {
    request({
      uri: 'https://rizzybeats.com/.netlify/functions/contentful',
      method: 'GET',
      json: true,
      qs: {
        contentType: 'merch',
        order: '-fields.price,fields.title'
      },
    }, (err, response, body) => {
      const assetMap = body.includes.Asset.reduce((acc, asset) => {
        return Object.assign(acc, { [asset.sys.id]: asset.fields.file.url });
      }, {});
      const newMerch = body.items.map((item) => {
        const {
          fields: {
            title,
            description,
            urlSlug,
            price,
            image: {
              sys: {
                id,
              } = {},
            } = {},
          } = {},
        } = item;
        return {
          title,
          price,
          description,
          image: assetMap[id],
          slug: urlSlug
        };
      });
      setMerch(newMerch);
    });
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          // height: '50px',
          fontSize: '36px',
          marginTop: '20px'
        }}
        className="bonnie shop-banner"
      >
        ALL YACHTVEMBER MERCH IS PRE-ORDER.<div/> ORDERS WILL BEGIN SHIPPING IN 2-4 WEEKS
      </div>
      <>
      {disableShop ? (<Timer seconds={secondsToGo} />) : <></>}
      </>
      <div style={{ marginTop: '20px' }} className="shop flex-grid">
        {
          merch.map((item, i)  => {
            return (
              <div
                className="merch"
                key={ item.slug }
              >
                <Link
                  style={{
                    textDecoration:'none',
                    color: 'white',
                    cursor: disableShop ? 'initial' : 'pointer'
                  }}
                  to={ disableShop ? '/shop' : `/shop/${ item.slug }` }
                >
                  <img
                    src={ `${item.image}?w=600` || 'http://via.placeholder.com/200x200' }
                    style={{
                      height: 'auto',
                      width: '100%',
                    }}
                    className="merch-image"
                    alt="empty"
                  />
                  <div className="merch-title">{item.title}</div>
                  <div className="merch-price">${item.price && item.price.toFixed(2)}</div>
                </Link>
              </div>
            )
          })
        }
      </div>
    </>
  );
}

export default connect(mapStateToProps, { addToCart, clearCart })(Shop);
