import React, { useState } from 'react';
import './App.css';
import request from 'request';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comments, setComments] = useState('');

  const [success, setSuccess] = useState(false);

  const handleSubmit = () => {
    request({
      // uri: 'https://deploy-preview-11--rizzybeats.netlify.app/.netlify/functions/contact',
      uri: 'https://rizzybeats.com/.netlify/functions/contact',
      method: 'POST',
      json: {
        email,
        name,
        comments
      }
    }, (err, response, body) => {
      setName('');
      setEmail('');
      setComments('');
      setSuccess(true);
    });
  }

  return (
    <div
      style={{
        // maxWidth: '100%'
      }}
    >
      <h2 style={{ fontSize: '32px' }} className="bonnie fc">
        { !success ? 'Get in touch' : 'Thanks for reaching out!' }
      </h2>
      <p style={{ fontSize: '20px' }} className="fc">
        {
          !success ? `Let's work together. For inquiries about production, features, or booking, send your name and information in the form listed below and I'll get back to you at my earliest convenience.`
          : ''
        }
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // maxWidth: '100%'
        }}
      >
        <div
          style={{
            maxWidth: '100%'
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '20px',
              width: '600px',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '95%'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '20px'
              }}
            >
              <TextField
                required
                id="outlined-required"
                label="Name"
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
                style={{
                  width: '250px'
                }}
              />
              <TextField
                required
                id="outlined-required"
                label="Email"
                variant="outlined"
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{
                  width: '250px'
                }}
              />
            </div>
            <TextField
              required
              id="outlined-required"
              label="Comments"
              variant="outlined"
              multiline
              rows={4}
              value={comments}
              onChange={e => setComments(e.target.value)}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px'
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{
                  width: '70%',
                  height: '40px',
                  fontSize: '18px'
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '50%',
            justifyContent: 'space-around',
            marginTop: '30px'
          }}
        >
          <div>
            <a href="https://www.facebook.com/beatsbyrizzy" target="_blank">
              <img alt="" src="/facebook.png"/>
            </a>
          </div>
          <div>
            <a href="https://twitter.com/rizzydohboss" target="_blank">
              <img alt="" src="/twitter.png"/>
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/rizzydohboss/" target="_blank">
              <img alt="" src="/instagram.png"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
