import {
  ADD_TO_CART,
  CLEAR_CART,
  OPEN_CART,
  CLOSE_CART,
  ADD_ONE,
  MINUS_ONE,
} from './actionTypes';

export const addToCart = item => dispatch => {
  dispatch({
    type: ADD_TO_CART,
    payload: { item }
  })
}

export const clearCart = () => dispatch => {
  dispatch({
    type: CLEAR_CART,
    payload: {}
  })
}

export const openCart = () => dispatch => {
  dispatch({
    type: OPEN_CART,
    payload: {}
  })
}

export const closeCart = () => dispatch => {
  dispatch({
    type: CLOSE_CART,
    payload: {}
  })
}

export const addOne = item => dispatch => {
  dispatch({
    type: ADD_ONE,
    payload: { item }
  })
}

export const minusOne = item => dispatch => {
  dispatch({
    type: MINUS_ONE,
    payload: { item }
  })
}
