import React, { useState, useEffect } from 'react';
import request from 'request';
import { connect } from 'react-redux';

import { addToCart } from './redux/actions'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: 'white',
    textAlign: 'center'
  },
  selectEmpty: {},
  tooltip: {
    height: '25px',
    fontSize: '14px'
  }
}));

const mapStateToProps = (state, ownProps) => {
  console.log('state', state);
  return { cartItems: state.cart.items, cartCount: Object.keys(state.cart.items).length };
}

function ShopItem(props) {
  const [item, setItem] = useState({});
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [selectedSize, setSelectedSize] = useState('');

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  const {
    match: {
      params: {
        itemSlug
      }
    }
  } = props;

  useEffect(() => {
    const baseUrl = 'https://cdn.contentful.com/spaces/j9eel39hrxng/environments/master/entries';
    const qs = {
      access_token: '06711dad040ed332146d711ed15914aa2e8a70d859975a94a89e05bebb19bd35',
      include: 1,
      content_type: 'merch',
      'fields.urlSlug': itemSlug,
    };
    request({
      uri: baseUrl,
      method: 'GET',
      json: true,
      qs,
    }, (err, response, body) => {
      const assetMap = body.includes.Asset.reduce((acc, asset) => {
        return Object.assign(acc, { [asset.sys.id]: asset.fields });
      }, {});
      const item = body.items.slice(0, 1).map((i) => {
        console.log(i);
        const {
          fields: {
            title,
            urlSlug,
            price,
            printfulId,
            stripeId,
            sizes,
            image: {
              sys: {
                id: mainImageId,
              } = {},
            } = {},
            altImages = []
          } = {},
        } = i;
        const mainImageUrl = assetMap[mainImageId].file.url;
        const altImageUrls = altImages.map(alt => {
          const sysId = alt.sys.id;
          return assetMap[sysId].file.url;
        })
        return {
          title,
          urlSlug,
          price,
          printfulId,
          stripeId,
          sizes: sizes,
          images: [mainImageUrl, ...altImageUrls]
        };
      })[0];
      setItem(item);
    });
  }, []);

  if (!item.images) return <></>

  const requiresSize = item.sizes && !!item.sizes.length;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '30px'
        }}
      >
        <div
          style={{
            width: '40%',
            minWidth: '40%'
          }}
        >
          <div>
            <img style={{ width: '100%' }} src={item.images[selectedImageIndex]} />
          </div>
          <div>
            {
              item.images.map((img, i) => {
                return (
                  <img key={img} onClick={() => setSelectedImageIndex(i)} style={{ cursor: 'pointer', width: '60px', marginTop: '5px', marginRight: '5px' }} src={img} />
                )
              })
            }
          </div>
        </div>
        <div
          style={{
            flexGrow: '1',
            marginLeft: '30px'
          }}
        >
          <div
            style={{
              fontSize: '22px'
            }}
          >{item.title}</div>
          <div
            style={{
              fontSize: '24px'
            }}
          >${item.price && item.price.toFixed(2)}</div>
          <div
            style={{
              fontSize: '16px'
            }}
          >{item.description}</div>
          <div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            {
              (item && item.sizes || []).map(size => {
                return (
                  <div
                    style={{
                      backgroundColor: selectedSize === size ? 'lightgrey' : 'darkgrey',
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      marginRight: '2px',
                      border: selectedSize === size ? '1px solid black' : '',
                      cursor: 'pointer'
                    }}
                    onClick={() => setSelectedSize(size)}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      { size }
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div
            style={{
              marginTop: '10px'
            }}
          >
            <Tooltip
              // disableHoverListener
              open={requiresSize && !selectedSize && tooltipOpen}
              onClose={() => setTooltipOpen(false)}
              onOpen={() => setTooltipOpen(true)}
              title="Please select a size first"
              classes={{ tooltip: classes.tooltip }}
            >
              <span>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  width: '200px',
                  height: '40px',
                  fontSize: '14px',
                  backgroundColor: 'white'
                }}
                onClick={() => {
                  const itemTitleToAdd = requiresSize ? `${item.title} - ${selectedSize}` : item.title;
                  // console.log(selectedSize, item.sizes, priceId);
                  const itemToAdd = Object.assign({}, item, {
                    title: itemTitleToAdd,
                  })
                  props.addToCart(itemToAdd)
                  setOpen(true);
                }}
                disabled={requiresSize && !selectedSize}
              >
                Add to Cart
              </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'middle' }} open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          { item.title } added to your cart!
        </Alert>
      </Snackbar>
    </>
  );
}

export default connect(mapStateToProps, { addToCart })(ShopItem);
