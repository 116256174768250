import React, { Component, Fragment } from 'react';
import {
  Link
} from 'react-router-dom'
import './App.css';
import Slider from 'react-slick';
import request from 'request';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};

    // display: block;
    // background: red;
    // margin-right: 25px;
    // z-index: 999;
    // height: 500px;
    // width: 40px;

function SampleNextArrow(props) {
  const {className, style, onClick} = props
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginRight: '25px', width: '40px'}}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginLeft: '45px', width: '40px', 'zIndex': '999'}}
      onClick={onClick}
    ></div>
  );
}

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slides: [],
      about: '',
      events: []
    };
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
  }

  componentWillMount() {
    this.setState({
      hovering: false,
    });
  }

  componentDidMount() {
    const baseUrl = 'https://cdn.contentful.com/spaces/j9eel39hrxng/environments/master/entries';
    const qs = {
      access_token: '06711dad040ed332146d711ed15914aa2e8a70d859975a94a89e05bebb19bd35',
      include: 1,
    };
    request({
      uri: baseUrl,
      method: 'GET',
      json: true,
      qs: {
        ...qs,
        content_type: 'slider',
      },
    }, (err, response, body) => {
      const assetMap = body.includes.Asset.reduce((acc, asset) => {
        return Object.assign(acc, { [asset.sys.id]: asset.fields.file.url });
      }, {});
      this.setState({
        slides: body.items.map((item) => {
          const {
            fields: {
              buttonLink,
              buttonText,
              caption,
              newTab,
              title,
              image: {
                sys: {
                  id,
                } = {},
              } = {},
            } = {},
          } = item;
          return {
            buttonlink: buttonLink,
            buttontext: buttonText,
            caption,
            newTab,
            image: assetMap[id] || 'fallback',
            title,
          };
        }),
      });
    });
    request({
      uri: baseUrl,
      method: 'GET',
      json: true,
      qs: {
        ...qs,
        content_type: 'about',
      },
    }, (err, response, body) => {
      this.setState({
        about: body.items[0].fields.bio
      });
    });
    request({
      uri: baseUrl,
      method: 'GET',
      json: true,
      qs: {
        ...qs,
        content_type: 'event',
        order: 'fields.date',
      },
    }, (err, response, body) => {
      this.setState({
        events: body.items.map(item => item.fields),
      });
    });
  }

  mouseEnter(e) {
    this.setState({
      hovering: true
    })
  }

  mouseLeave(e) {
    this.setState({
      hovering: false
    })
  }

  render() {
    const upcoming = this.state.events.filter(e => {
      if (!e.date) return false;

      return (new Date(e.date)) - (new Date()) > 0;
    });
    return (
      <div>
        <div id="mySlides">
          {
            this.state.slides.length ? (
              <Slider {...settings}>
                {
                  this.state.slides.map((slide, i) => {
                    return (
                      <div className={ i===0 ? "item active" : "item" } key={ slide.title } onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                        <img className={ this.state.hovering ? "mySlide blurred" : "mySlide"} src={ slide.image } alt="Slide"/>
                        <div className={ this.state.hovering ? "mySlideCaption" : "mySlideCaption hidden"}>
                          <div>
                            <h1 className="bonnie">{ slide.title }</h1>
                            <p>{ slide.caption }</p>
                            <p>
                              {
                                slide.buttonlink.indexOf("http") > -1 ? 
                                  (
                                    <a className="btn btn-lg btn-primary bonnie" href={ slide.buttonlink }>{ slide.buttontext }</a>
                                  )
                                 : 
                                  (
                                    <Link className="btn btn-lg btn-primary bonnie" to={ slide.buttonlink }>{ slide.buttontext }</Link>
                                  )
                                
                              }
                              </p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </Slider>
            ) : null
          }
        </div>

        <div className="container marketing tc">
          <div className="about">
            <h4 className="fc bonnie">About</h4>
            <ul className="list-group">
              <li className="list-group-item" id="li-about">
                <div>
                  <p id="aboutrizzy">
                    {
                      this.state.about.split('\n').map(sentence => {
                        return (<p key={ sentence } >{ sentence }</p>);
                      })
                    }
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <>
            {
              upcoming.length ? (
                <div className="upcoming bonnie">
                  <h4 className="fc bonnie">Upcoming Shows</h4>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <div>
                        {
                          upcoming.map((event) => {
                            return (
                              <div key={event.title}>
                                <div className="eventName">
                                  { event.title }
                                </div>
                                <div className="eventDate">
                                  { event.date.split('T')[0] }
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </li>
                  </ul>
                </div>
              ) : <></>
            }
          </>
          <span>
            {
              this.state.slides.length ? (
                <div className="sc-embed row">
                  <div className="col-12">
                    <h4 className="album-heading fc bonnie">Rizzy's Beats</h4>
                    <iframe title="RizzyBeats SoundCloud embed" width="100%" height="450" scrolling="no" frameBorder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/16454456&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false"></iframe>
                  </div>
                  <div className="col-12">
                    <h4 className="album-heading fc bonnie">Beats and Coffee</h4>
                    <iframe title="Beats and Coffee SoundCloud embed" width="100%" height="450" scrolling="no" frameBorder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/212563756&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false"></iframe>
                  </div>
                </div>
              ) : null
            }
          </span>
        </div>
      </div>
    );
  }
}

export default Home;
