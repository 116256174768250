import React, { useState, useEffect, useRef } from 'react';
import request from 'request';
import { useSwipeable, Swipeable } from 'react-swipeable'

import { makeStyles } from '@material-ui/core/styles';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: 'white',
    textAlign: 'center'
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
    // textAlign: 'center'
  },
}));

function useWindowSize() {

  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

// Hook
function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}

const getInner = (img, hidden, size, pause) => {
  console.log('getInner', img);
  if (!img || !img.image) return (<></>)
  const visibility = hidden ? 'hidden' : 'visible'
  if (img.image.indexOf('mp4') > 0) {
    return (
      <video onPlay={pause} key={img.image} controls style={{ height: '100%', visibility }}>
        <source src={img.image} type="video/mp4" />
      </video>
    )
  }
  return (
    <img
      style={{
        height: size.width > 500 ? '100%' : 'auto',
        width: size.width > 500 ? 'auto' : '100%',
        visibility
      }}
      src={img.image}
    />
  )
}


function Gallery(props) {
  const size = useWindowSize();
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);

  const [filter, setFilter] = useState('');

  const imagesToShow = images.filter(i => {
    if (!filter) return true;
    if (filter === 'photos') {
      if (i.image.includes('mp4')) return false;
      else return true;
    }
    else {
      if (i.image.includes('mp4')) return true;
      else return false;
    }
  })

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const [cols, setCols] = useState(2);

  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);

  const leftPress = useKeyPress("ArrowLeft");
  const rightPress = useKeyPress("ArrowRight");

  useEffect(() => {
    const newCols = size.width < 600 ? 2 : 3;
    setCols(newCols);
  }, [size]);

  const handleClose = () => setOpen(false);
  const handleAssetClick = i => {
    setSelectedAssetIndex(i);
    setOpen(true);
  }

  const getModalStyle = asset => {
    const src = asset && asset.image;
    const style = {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      position: 'absolute',
      height: size.width > 500 ? '70%' : 'auto',
      width: size.width > 500 ? 'auto' : '100%',
      maxWidth: '100%',
      outline: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      flexDirection: 'column'
    }
    if (src && src.indexOf('mp4') > 0) {
      style.minWidth = '600px';
    }
    return style;
  }

  const moveLeft = () => {
    const newIndex = selectedAssetIndex - 1;
    if (newIndex >= 0) setSelectedAssetIndex(newIndex)
  }

  const moveRight = () => {
    const newIndex = selectedAssetIndex + 1;
    if (newIndex < imagesToShow.length) {
      setSelectedAssetIndex(newIndex)
    }
  }

  const pause = () => {
    console.log('apusing???');
    props.pause()
  }

  const handlers = useSwipeable({ onSwipedLeft: moveRight, onSwipedRight: moveLeft, preventDefaultTouchmoveEvent: true })

  useEffect(() => {
    if (open && leftPress) {
      moveLeft() 
    }
  }, [leftPress])

  useEffect(() => {
    if (open && rightPress) {
      moveRight() 
    }
  }, [rightPress])

  useEffect(() => {
    const baseUrl = 'https://cdn.contentful.com/spaces/j9eel39hrxng/environments/master/entries';
    const qs = {
      access_token: '06711dad040ed332146d711ed15914aa2e8a70d859975a94a89e05bebb19bd35',
      // include: 1,
    };
    request({
      uri: baseUrl,
      method: 'GET',
      json: true,
      qs: {
        ...qs,
        content_type: 'gallery',
      },
    }, (err, response, body) => {
      const assetMap = body.includes.Asset.reduce((acc, asset) =>
        Object.assign(acc, { [asset.sys.id]: { ...asset.fields, ...asset.sys } }), {});
      const gallery = body.items[0];
      const newImages = gallery.fields.images.map((img) => {
        const { sys: { id } } = img;
        const { file: asset, title, createdAt } = assetMap[id];

        const {
          details: {
            image: {
              width = 0,
              height = 0
            } = {},
          } = {},
        } = asset;

        return {
          image: asset.url.replace('//', 'https://'),
          width,
          height,
          title,
          id,
          createdAt,
        };
      })
      console.log(newImages)
      setImages(newImages.sort((a, b) => (new Date(b.createdAt)) - (new Date(a.createdAt))));
    });
  }, []);

  const classes = useStyles();
  return (
    <div id="gallery">
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end'
        }}
      >
        <FormControl className={classes.formControl}>
          <Select
            value={filter}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={''}>Photos + Videos</MenuItem>
            <MenuItem value={'photos'}>Photos</MenuItem>
            <MenuItem value={'videos'}>Videos</MenuItem>
          </Select>
        </FormControl>
      </div>
      <GridList style={{width: '100%'}} cellHeight={160} className={classes.gridList} cols={cols}>
        {imagesToShow.map((tile, i) => {
          return (
            <GridListTile
              key={tile.image}
              cols={tile.cols || 1}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAssetClick(i)}
            >
                {
                  (tile.image.indexOf('mp4') > -1) ? (
                    <div>
                      <div style={{ position: 'absolute', height: '100%' }}>
                        <video style={{ width: '100%', minHeight: '100%' }} muted type="video/mp4">
                          <source src={tile.image} type="video/mp4" />
                        </video>
                      </div>
                      <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div><PlayCircleOutlineIcon style={{ fontSize: '65px' }} /></div>
                      </div>
                    </div>
                  ) : (
                    <img src={`${tile.image}?w=300`} alt={tile.title} />
                  )
                }
            </GridListTile>
          )
        })}
      </GridList>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={getModalStyle(imagesToShow[selectedAssetIndex])}
          {...handlers}
        >
          <div
            style={{
              height: size.width > 500 ? '100%' : 'auto',
              width: size.width > 500 ? 'auto' : '100%',
              position: 'absolute',
              zIndex: 999
            }}
          >
            {getInner(imagesToShow[selectedAssetIndex], false, size, pause)}
            <div
              style={{
                height: '30px',
                width: '30px',
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CancelOutlinedIcon onClick={handleClose} style={{ cursor: 'pointer', float: 'right', fontSize: '24px' }}/>
              {
                // getInner(images[selectedAssetIndex], true, size)
              }
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Gallery;
