import React, { useState } from 'react';
import './App.css';
import Header from './header'
import Footer from './footer'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'
import Home from './home'
import Albums from './albums'
import Album from './album'
import Events from './events'
import Gallery from './gallery'
import Shop from './shop'
import ShopItem from './ShopItem'
import Contact from './contact'
import Player from './player'
import Admin from './admin'
import Cart from './Cart'
import CartIcon from './CartIcon';
import CheckoutSuccess from './CheckoutSuccess';
import {Helmet} from 'react-helmet';

function App() {
  const [playing, setPlaying] = useState(true);
  const [currentTrack, setCurrentTrack] = useState({});

  const updatePLaying = e => setCurrentTrack(e);

  const pause = () => {
    // alert('paused')
    setPlaying(false);
  }

  const play = () => {
    setPlaying(true);
  }

  // constructor() {
  //   super();
  //   this.state = {
  //     playing: true,
  //     track: {},
  //   };
  //   this.updatePLaying = this.updatePLaying.bind(this);
  // }

  // updatePLaying(clickPayload) {
  //   this.setState({
  //     track: clickPayload,
  //   });
  // }


    return (
      <div>
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          <meta property="fb:app_id"             content="141252499655754" />

          <meta property="og:url"                content="https://www.rizzybeats.com" />
          <meta property="og:title"              content="RizzyBeats" />
          <meta property="og:image"              content="https://www.rizzybeats.com/og_img_small.png" />
          <meta name="twitter:title" content="RizzyBeats" />
          <meta name="twitter:image" content="https://www.rizzybeats.com/og_img_small.png" />

          <meta property="og:description"        content="Richard Humbles (born April 14, 1994), professionally known as RizzyBeats is an American hip-hop producer and rapper from Wilmington, NC. He began seriously making beats at the age of 17 with fellow producer/rapper Thesis. After releasing his debut under the Coastal Connection name, RizzyBeats began releasing several instrumental projects and also fully produced an EP for friend and frequent collaborator, Wethaman. He is currently a member of the Beats & Coffee crew, a Wilmington-based hip-hop producer collective, and releases new music with them on a weekly basis. His most recent release, The StarLove EP, was his first EP with the Beats & Coffee crew." />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@rizzydohboss" />
          <meta name="twitter:creator" content="@rizzydohboss" />

          <meta name="twitter:description" content="Richard Humbles (born April 14, 1994), professionally known as RizzyBeats is an American hip-hop producer and rapper from Wilmington, NC. He began seriously making beats at the age of 17 with fellow producer/rapper Thesis. After releasing his debut under the Coastal Connection name, RizzyBeats began releasing several instrumental projects and also fully produced an EP for friend and frequent collaborator, Wethaman. He is currently a member of the Beats & Coffee crew, a Wilmington-based hip-hop producer collective, and releases new music with them on a weekly basis. His most recent release, The StarLove EP, was his first EP with the Beats & Coffee crew." />

          <title>RizzyBeats</title>
        </Helmet>
        <Router>
          <div className="container">
            <Header/>
            <Route exact path="/" component={Home}/>
            <Route exact path="/events" component={Events}/>
            <Route exact path="/events/:eventSlug" component={Events}/>
            <Route exact path="/music" component={Albums}/>
            <Route exact path="/music/:albumSlug" render={(props) =><Album match={props.match} update={updatePLaying}/>}/>
            <Route exact path="/shop" component={Shop}/>
            <Route exact path="/shop/:itemSlug" component={ShopItem}/>
            <Route exact path="/gallery" render={(props) => <Gallery pause={pause} />} />
            <Route exact path="/gallery/:imageSlug" component={Gallery}/>
            <Route exact path="/contact" component={Contact} pause={pause} />
            <Route exact path="/admin" component={Admin}/>
            <Route exact path="/checkout-success" component={CheckoutSuccess} />
            <Footer/>
            <CartIcon />
            <Player play={play} pause={pause} playing={playing} track={currentTrack} update={updatePLaying}/>
            <Cart />
          </div>
        </Router>
      </div>
    );
  
}

export default App;
