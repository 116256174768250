import React, { Component } from 'react';
import './App.css';
// import PlayIcon from 'react-icons/lib/fa/play';
// import Back from 'react-icons/lib/fa/step-backward';
// import Forward from 'react-icons/lib/fa/step-forward';

class AlbumPlayer extends Component {
  handleClick(track, previous, next) {

    const t = {
      url: track.url,
      artist: 'RizzyBeats',
      album: this.props.title,
      title: track.title,
      length: track.length,
    }


    t.album_idx = 0;
    t.track_idx = 0;


    this.props.click(t);
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: 'rgba(211,211,211, 0.9)',
          fontSize: '16px',
          padding: '5px',
          color: 'black',
          borderRadius: '5px',
        }}
      >
        <div
          style={{
            paddingBottom: '10px'
          }}
        >
          {this.props.title}
          <br/>
          by RizzyBeats
        </div>
        <div>
          {
            this.props.songs.map((track, index) => {
              return (
                <div
                  onClick={() => { this.handleClick(track, this.props.songs[index -1], this.props.songs[index + 1]); }}
                  key={ `${ track.title }-${ index }` }
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    borderTop: '1px solid black',
                    cursor: 'pointer'
                  }}
                >
                  {index + 1}. {track.title}
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default AlbumPlayer;
