import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import request from 'request';

class Albums extends Component {
  componentDidMount() {
    const baseUrl = 'https://cdn.contentful.com/spaces/j9eel39hrxng/environments/master/entries';
    const qs = {
      access_token: '06711dad040ed332146d711ed15914aa2e8a70d859975a94a89e05bebb19bd35',
      include: 1,
    };
    request({
      uri: baseUrl,
      method: 'GET',
      json: true,
      qs: {
        ...qs,
        content_type: 'album',
        order: '-fields.releaseDate',
      },
    }, (err, response, body) => {
      const assetMap = body.includes.Asset.reduce((acc, asset) => {
        return Object.assign(acc, { [asset.sys.id]: asset.fields.file.url });
      }, {});
      this.setState({
        albums: body.items.map((item) => {
          const {
            fields: {
              title,
              artist,
              slug,
              backCover: {
                sys: {
                  id: backId,
                } = {},
              } = {},
              frontCover: {
                sys: {
                  id: frontId,
                } = {},
              } = {},
            } = {},
          } = item;
          return {
            title,
            slug,
            front: assetMap[frontId],
          };
        }),
      });
    });
  }
  
  render() {
    return (
      <div id="albums">
      	{
      		this.state && this.state.albums.map((album) => {
            console.dir(album);
            if (!album.title) return null;
      			return (
      				<div className="album" key={ album.title }>
      					<Link to={ `/music/${ album.slug }` }>
      						<img src={ album.front } alt="" />
      					</Link>
      				</div>
      			)
      		})
      	}
      </div>
    );
  }
}

export default Albums;
