import React, { useState } from 'react';
import { connect } from 'react-redux';
import request from 'request';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CloseIcon from '@material-ui/icons/Close';

import { clearCart, closeCart, addOne, minusOne } from './redux/actions';

const mapStateToProps = (state, ownProps) => {
  console.log('state', state);
  return { cartItems: state.cart.items, open: state.cart.open };
}

function Cart(props) {
  const [shipping, setShipping] = useState(true);
  const { open, cartItems } = props;

  console.log(cartItems);

  const total = Object.values(cartItems).reduce((acc, item) => {
    return Number(acc) + (Number(item.count) * Number(item.price));
  }, 0);

  const handleStripeClick = () => {
    request({
      // uri: 'https://deploy-preview-23--rizzybeats.netlify.app/.netlify/functions/checkout',
      uri: 'https://rizzybeats.com/.netlify/functions/checkout',
      method: 'POST',
      json: {
        items: Object.values(cartItems).map(item => ({
          title: item.title,
          price: item.price,
          quantity: item.count,
          image: `http:${item.image}`
        })),
        shipping,
        cancelUrl: window.location.href,
        successUrl: `http://${window.location.host}/checkout-success`,
      },
    }, (err, response, body) => {
      if (body.url) {
        window.location = body.url;
      }
    })
  }

  return (
    <Drawer anchor="right" open={false || open} onClose={props.closeCart}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          width: '300px'
        }}
      >
        <div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '22px',
              marginTop: '20px'
            }}
          >
            Your Cart
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '12px',
              cursor: 'pointer'
            }}
            onClick={props.clearCart}
          >
            (clear)
          </div>
          <div
            style={{
              margin: '10px'
            }}
          >
            {
              Object.entries(cartItems).map(item => {
                return (
                  <div key={item[0]}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                        marginTop: '10px'
                      }}
                    >
                      <img style={{ height: '60px', width: 'auto' }} src={item[1].image} />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end'
                        }}
                      >
                        <div>{item[0]}</div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '50px',
                          }}
                        >
                          <div style={{ lineHeight: '100%' }}><RemoveOutlinedIcon onClick={() => props.minusOne(item[1])} style={{ fontSize: '18px', cursor: 'pointer' }}/></div>
                          <div
                            style={{
                              width: '20px',
                              textAlign: 'center'
                            }}
                          >
                            {item[1].count}
                          </div>
                          <div style={{ lineHeight: '100%' }}><AddOutlinedIcon onClick={() => props.addOne(item[1])} style={{ fontSize: '18px', cursor: 'pointer' }}/></div>
                        </div>
                        <div>${item[1].price * item[1].count}</div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                )
              })
            }
          </div>
        </div>
        <div
          style={{
            visibility: !Object.keys(cartItems).length ? 'hidden' : 'inherit' 
          }}
        >
          <div
            style={{
              textAlign: 'right',
              margin: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              fontSize: '22px'
            }}
          >
            <div>Local Pickup:</div>
            <input
              style={{
                fontSize: '12px',
                width: '25px'
              }}
              type="checkbox"
              checked={!shipping}
              onChange={() => setShipping(!shipping)}
            />
          </div>
          <div
            style={{
              textAlign: 'right',
              margin: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              fontSize: '22px'
            }}
          >
            <div>Subtotal:</div>
            <div>${ total.toFixed(2) }</div>
          </div>
          <>
            {
              shipping ? (
                <div
                  style={{
                    textAlign: 'right',
                    margin: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontSize: '22px'
                  }}
                >
                  <div>Shipping:</div>
                  <div>$5.00</div>
                </div>
              ) : <></>
            }
          </>
          <div
            style={{
              textAlign: 'right',
              margin: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              fontSize: '22px'
            }}
          >
            <div>Total:</div>
            <div>${ Number(total + (shipping ? 5.00 : 0)).toFixed(2) }</div>
          </div>
          <div
            style={{
              height: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                width: '70%',
                height: '40px',
                fontSize: '18px'
              }}
              onClick={handleStripeClick}
              disabled={!Object.keys(cartItems).length}
            >
              Checkout
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          cursor: 'pointer'
        }}
        onClick={props.closeCart}
      >
        <CloseIcon style={{ fontSize: '24px' }} />
      </div>
    </Drawer>
  );
}

export default connect(mapStateToProps, { clearCart, closeCart, addOne, minusOne })(Cart);
