import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

class Library extends Component {
  constructor(props) {
    super(props);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.albums && this.props.albums.length) return;
    if (!nextProps.albums || !nextProps.albums.length) return;
    const { albums } = nextProps;
    const rand = Math.floor(Math.random() * albums.length);

    const album = albums[rand];
    const { tracks } = album;
    const r2 = Math.floor(Math.random() * tracks.length);
    const track = tracks[r2];
    const next = tracks[r2 + 1];
    const previous = tracks[r2 - 1];
    track.album = album.title;
    track.artist = album.artist;

    track.album_idx = rand;
    track.track_idx = r2;

    this.update(track)();
  }
  update = clickPayload => e => {
    this.props.update(clickPayload)
  }

  render() {
    const styles = {};

    const { albums } = this.props;

    if(this.props.shown) {
      // styles.maxHeight = '500px';
      styles.visibility = 'initial';
    }

    return (
      <div id="tracks" style={ styles }>
        <Accordion>
        	{
            albums.map((album, idx)=>{
              return (
                <AccordionItem key={ `${ album.title }-${ idx }` }>
                  <div key={ `${ album.title }-${ idx }` }>
                    <AccordionItemTitle>
                      { album.title }
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      {
                        album.tracks.map((track, trackIdx) => {
                          const clickPayload = {
                            album: album.title,
                            title: track.title,
                            url: track.url,
                            artist: album.artist,
                            length: track.length,
                            album_idx: idx,
                            track_idx: trackIdx,
                          };

                          return (
                            <div onClick={ this.update(clickPayload) } key={ `${ track.title }-${ trackIdx }` }>
                              { track.title }
                            </div>
                          )
                        })
                      }
                    </AccordionItemBody>
                  </div>
                </AccordionItem>
              )
            })
          }
        </Accordion>
      </div>
    );
  }
}

export default Library;
