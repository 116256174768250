import React, { Component } from 'react';
import './App.css';

class Footer extends Component {
  render() {
    return (
      <div>
      	© 2022 RizzyBeats
      </div>
    );
  }
}

export default Footer;
