import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom'
import './App.css';

class Nav extends Component {
  render() {
    return (
      <div id="nav">
      	<div className="heading">
      		<Link to="/">
      			RizzyBeats
      		</Link>
      	</div>
	      <ul>
	      	<li><Link to="/music">Music</Link></li>
	        <li><Link to="/events">Events</Link></li>
	        <li><Link to="/shop">Store</Link></li>
	        <li><Link to="/gallery">Gallery</Link></li>
	        <li><Link to="/contact">Contact</Link></li>
	      </ul>
      </div>
    );
  }
}

export default Nav;
